<div class="dash-block">
  <div class="dash-block__header">
    <div class="dash-block__title">{{ title }}</div>
  </div>
  <div [ngClass]="mode === 'widget' ? 'dash-block__widget-graph' : 'dash-block__report-graph'">
    <content-state *ngIf="isLoading; else loaded" [isLoading]="isLoading" size="extra-small"> </content-state>
    <ng-template #loaded>
      <sb-chart [type]="chartType" [data]="data" [options]="options" [height]="height"></sb-chart>
    </ng-template>
    <!-- chart is shown outside content-state so that it can autoscale -->
  </div>
</div>
