import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartType, SbChartData, SbChartOptions } from '@app/shared/sb-lib/chart/chart.model';
import { TranslateService } from '@ngx-translate/core';
import { ChartTurnoverResponse } from '../../models';
import { ChartHelperService } from '../../services/chart-helper.service';
import { GraphMode, GraphSize, TurnoverGraphDataType } from '../../enums';

@Component({
  selector: 'app-turnover-graph',
  templateUrl: './turnover-graph.component.html',
})
export class TurnoverGraphComponent implements OnChanges {
  @Input()
  public graphData!: ChartTurnoverResponse;
  @Input()
  public isLoading = false;
  @Input()
  public mode: GraphMode = GraphMode.GRAPH;
  @Input()
  public size: GraphSize = GraphSize.MEDIUM;
  @Input()
  public graphType!: TurnoverGraphDataType;

  public readonly chartType = ChartType.LINE;

  public data: SbChartData;
  public height: string;
  public title: string;

  public readonly options: SbChartOptions;

  public constructor(private chartHelper: ChartHelperService, private translate: TranslateService) {
    this.options = chartHelper.defaultOptions;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.size) {
      this.height = this.chartHelper.getHeight(this.size);
    }
    if (changes.graphData || changes.graphType) {
      this.data = this.getData();
    }
    if (changes.graphType) {
      this.title = this.getTitle();
    }
  }

  private getData(): SbChartData {
    if (!this.graphType || !this.graphData) {
      return null;
    }
    return this.chartHelper.convertChartResponseToSbLineChartData(this.graphData, 'turnover', this.graphType);
  }

  public getTitle(): string {
    switch (this.graphType) {
      case TurnoverGraphDataType.SALARY_COST_PERCENTAGE: {
        return this.translate.instant('Salary cost percentage');
      }
      case TurnoverGraphDataType.COC_PERCENTAGE: {
        return this.translate.instant('Salary cost percentage including CoC');
      }
      case TurnoverGraphDataType.SICK_LEAVE_PERCENTAGE: {
        return this.translate.instant('Absence percentage');
      }
      case TurnoverGraphDataType.TURNOVER_PER_HOUR: {
        return this.translate.instant('Turnover per hour');
      }
      case TurnoverGraphDataType.SALARY_PER_HOUR: {
        return this.translate.instant('Salary per hour');
      }
      default: {
        return '';
      }
    }
  }
}
