import { Injectable } from '@angular/core';
import { SbChartData, SbChartOptions } from '@app/shared/sb-lib/chart/chart.model';
import { hslToHex } from '@shiftbase-com/utilities';

import { GraphSize } from '../enums';
import { ChartScheduleResponse, ChartTurnoverResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ChartHelperService {
  public defaultOptions: SbChartOptions = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 20,
          usePointStyle: false,
          pointStyle: 'rectRounded',
        },
      },
      tooltip: {
        position: 'nearest',
      },
    },
    responsive: true,
  };
  public colorScheme = {
    domain: [],
  };

  private getColors(size: number): string[] {
    if (!size) {
      return [];
    }

    const colors = [];
    let i = 0;

    while (i < size) {
      const hslColor = Math.round((360 / size) * i);
      const hexColor = hslToHex(hslColor, 100, 65);

      colors.push(hexColor);
      i++;
    }

    return colors;
  }

  public getHeight(size: GraphSize): string {
    switch (size) {
      case GraphSize.SMALL: {
        return '270px';
      }
      default: {
        return '400px';
      }
    }
  }

  public convertChartResponseToSbLineChartData(
    chartResponse: ChartScheduleResponse | ChartTurnoverResponse,
    groupName: string,
    propertyName: string,
  ): SbChartData {
    if (this.colorScheme.domain.length !== Object.keys(chartResponse[groupName]).length) {
      const colors = this.getColors(Object.keys(chartResponse[groupName]).length);
      this.colorScheme.domain = colors;
    }

    const data = Object.keys(chartResponse[groupName]).map((key, index) => ({
      label: key.replace(/\([0-9]*?\)/g, ''),
      data: chartResponse[groupName][key].map((item) => item[propertyName]),
      borderColor: this.colorScheme.domain[index],
      backgroundColor: this.colorScheme.domain[index],
      tension: 0.2,
      cubicInterpolationMode: 'monotone',
      borderWidth: 3,
      pointBorderColor: this.colorScheme.domain[index],
      pointBackgroundColor: this.colorScheme.domain[index],
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: this.colorScheme.domain[index],
      pointHoverRadius: 8,
      pointHoverBorderWidth: 3,
      pointHitDetectionRadius: 35,
    }));

    return {
      labels: chartResponse.labels,
      datasets: data,
    };
  }
}
