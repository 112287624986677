export enum GraphMode {
  WIDGET = 'widget',
  GRAPH = 'graph',
}

export enum GraphSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export enum TurnoverGraphDataType {
  SALARY_COST_PERCENTAGE = 'salary_cost_percentage',
  COC_PERCENTAGE = 'coc_percentage',
  SICK_LEAVE_PERCENTAGE = 'sick_leave_percentage',
  SALARY_PER_HOUR = 'salary_per_hour',
  TURNOVER_PER_HOUR = 'turnover_per_hour',
}
