import { Injectable } from '@angular/core';
import { ApiGateway } from '@app/api/ApiGateway.service';
import { Observable } from 'rxjs';
import { ChartScheduleResponse, ChartTurnoverResponse, ChartTurnoverSummaryResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ChartApiService {
  private endpoint = 'charts';

  public constructor(private apiGateway: ApiGateway) {}

  public turnoverSummary(filter): Observable<ChartTurnoverSummaryResponse> {
    return this.apiGateway.post(`${this.endpoint}/turnover_summary`, filter);
  }

  public turnover(filter): Observable<ChartTurnoverResponse> {
    return this.apiGateway.post(`${this.endpoint}/turnover`, filter);
  }

  public schedule(filter): Observable<ChartScheduleResponse> {
    return this.apiGateway.post(`${this.endpoint}/schedule_timesheet`, filter);
  }
}
