import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ReportFilterValues } from '@app/+authenticated/+reports/shared/report-filters/report-filter.interfaces';
import { SbChartData, ChartType, SbChartOptions } from '@app/shared/sb-lib/chart/chart.model';
import { Subscription } from 'rxjs';
import { GraphMode, GraphSize } from '../../enums';
import { ChartApiService } from '../../services/chart-api.service';
import { ChartHelperService } from '../../services/chart-helper.service';

@Component({
  selector: 'app-worked-hours-graph',
  templateUrl: './worked-hours-graph.component.html',
})
export class WorkedHoursGraphComponent implements OnChanges, OnDestroy {
  @Input()
  public filterValues: ReportFilterValues[];
  @Input()
  public mode: GraphMode = GraphMode.GRAPH;
  @Input()
  public size: GraphSize = GraphSize.MEDIUM;

  public isLoading = false;
  public data: SbChartData;

  private dataSubs = new Subscription();
  public chartType = ChartType.LINE;
  public height: string;

  public options: SbChartOptions;

  public constructor(private chartHelper: ChartHelperService, private readonly chartApi: ChartApiService) {
    this.options = chartHelper.defaultOptions;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.size) {
      this.height = this.chartHelper.getHeight(this.size);
    }
    if (!changes.filterValues) {
      return;
    }

    this.isLoading = true;

    this.dataSubs.add(
      this.chartApi.schedule(this.filterValues).subscribe((chartResponse) => {
        this.data = this.chartHelper.convertChartResponseToSbLineChartData(
          chartResponse,
          'differences',
          'difference_percentage'
        );
        this.isLoading = false;
      })
    );
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }
}
